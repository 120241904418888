@font-face {
    font-family: 'Ligo';
    src: url('Ligo.ttf') format('truetype');
}

@font-face {
    font-family: 'MonumentExtended';
    font-style: normal;
    font-weight: 100;
    src: url('MonumentExtended-Regular.otf');
    /* IE9 Compat Modes */
    src: local('MonumentExtended'), url('MonumentExtended-Regular.otf') format('truetype');
    /* Safari, Android, iOS */
    color: rgb(146, 146, 146);
}


@font-face {
    font-family: 'MonumentExtendedBold';
    font-style: normal;
    font-weight: 100;
    src: url('MonumentExtended-Ultrabold.otf');
    /* IE9 Compat Modes */
    src: local('MonumentExtendedBold'), url('MonumentExtended-Ultrabold.otf') format('truetype');
    /* Safari, Android, iOS */
    color: rgb(146, 146, 146);
}


/* scholarships fonts start from here */

/* Raleway */

@font-face {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 100;
    src: url('Raleway-Thin.ttf');
    /* IE9 Compat Modes */
    src: local('Raleway'), url('Raleway-Thin.ttf') format('truetype');
    /* Safari, Android, iOS */
    color: rgb(146, 146, 146);
}

@font-face {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 200;
    src: url('Raleway-ExtraLight.ttf');
    /* IE9 Compat Modes */
    src: local('Raleway'), url('Raleway-ExtraLight.ttf') format('truetype');
    /* Safari, Android, iOS */
}

@font-face {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 300;
    src: url('Raleway-Light.ttf');
    /* IE9 Compat Modes */
    src: local('Raleway'), url('Raleway-Light.ttf') format('truetype');
    /* Safari, Android, iOS */
}

@font-face {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 400;
    src: url('Raleway-Regular.ttf');
    /* IE9 Compat Modes */
    src: local('Raleway'), url('Raleway-Regular.ttf') format('truetype');
    /* Safari, Android, iOS */
}

@font-face {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 500;
    src: url('Raleway-Medium.ttf');
    /* IE9 Compat Modes */
    src: local('Raleway'), url('Raleway-Medium.ttf') format('truetype');
    /* Safari, Android, iOS */
}

@font-face {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 600;
    src: url('Raleway-SemiBold.ttf');
    /* IE9 Compat Modes */
    src: local('Raleway'), url('Raleway-SemiBold.ttf') format('truetype');
    /* Safari, Android, iOS */
}

@font-face {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 700;
    src: url('Raleway-Bold.ttf');
    /* IE9 Compat Modes */
    src: local('Raleway'), url('Raleway-Bold.ttf') format('truetype');
    /* Safari, Android, iOS */
}

@font-face {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 800;
    src: url('Raleway-ExtraBold.ttf');
    /* IE9 Compat Modes */
    src: local('Raleway'), url('Raleway-ExtraBold.ttf') format('truetype');
    /* Safari, Android, iOS */
}

@font-face {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 800;
    src: url('Raleway-Black.ttf');
    /* IE9 Compat Modes */
    src: local('Raleway'), url('Raleway-Black.ttf') format('truetype');
    /* Safari, Android, iOS */
}


/* YesevaOne */

@font-face {
    font-family: 'YesevaOne';
    font-style: normal;
    src: url('YesevaOne-Regular.ttf');
    /* IE9 Compat Modes */
    src: local('YesevaOne'), url('YesevaOne-Regular.ttf') format('truetype');
    /* Safari, Android, iOS */
}

/* Manrope */

@font-face {
    font-family: 'Manrope';
    font-style: normal;
    src: url('Manrope-Regular.otf');
    /* IE9 Compat Modes */
    src: local('Manrope'), url('Manrope-Regular.otf') format('opentype');
    /* Safari, Android, iOS */
}

/* Ubuntu */

@font-face {
    font-family: 'Ubuntu';
    font-style: normal;
    font-weight: 300;
    src: url('Ubuntu-Regular.ttf');
    /* IE9 Compat Modes */
    src: local('Raleway'), url('Ubuntu-Regular.ttf') format('truetype');
    /* Safari, Android, iOS */
}

@font-face {
    font-family: 'Ubuntu';
    font-style: normal;
    font-weight: 400;
    src: url('Ubuntu-Regular.ttf');
    src: local('Raleway'), url('Ubuntu-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'Ubuntu';
    font-style: normal;
    font-weight: 500;
    src: url('Ubuntu-Medium.ttf');
    /* IE9 Compat Modes */
    src: local('Raleway'), url('Ubuntu-Medium.ttf') format('truetype');
    /* Safari, Android, iOS */
}

@font-face {
    font-family: 'Ubuntu';
    font-style: normal;
    font-weight: 700;
    src: url('Ubuntu-Bold.ttf');
    /* IE9 Compat Modes */
    src: local('Raleway'), url('Ubuntu-Bold.ttf') format('truetype');
    /* Safari, Android, iOS */
}
/* =================================================================  */
/* Inter */

@font-face {
    font-family: "Inter";
    font-style: normal;
    font-weight: 100;
    src: url("./Inter/static/Inter-Thin.ttf"); /* IE9 Compat Modes */
    src: local("Inter"), url("./Inter/static/Inter-Thin.ttf") format("truetype"); /* Safari, Android, iOS */
  }
  
  @font-face {
    font-family: "Inter";
    font-style: normal;
    font-weight: 200;
    src: url("./Inter/static/Inter-ExtraLight.ttf"); /* IE9 Compat Modes */
    src: local("Inter"),
      url("./Inter/static/Inter-ExtraLight.ttf") format("truetype"); /* Safari, Android, iOS */
  }
  @font-face {
    font-family: "Inter";
    font-style: normal;
    font-weight: 300;
    src: url("./Inter/static/Inter-Light.ttf"); /* IE9 Compat Modes */
    src: local("Inter"), url("./Inter/static/Inter-Light.ttf") format("truetype"); /* Safari, Android, iOS */
  }
  @font-face {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    src: url("./Inter/static/Inter-Regular.ttf"); /* IE9 Compat Modes */
    src: local("Inter"),
      url("./Inter/static/Inter-Regular.ttf") format("truetype"); /* Safari, Android, iOS */
  }
  @font-face {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    src: url("./Inter/static/Inter-Medium.ttf"); /* IE9 Compat Modes */
    src: local("Inter"), url("./Inter/static/Inter-Medium.ttf") format("truetype"); /* Safari, Android, iOS */
  }
  @font-face {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    src: url("./Inter/static/Inter-SemiBold.ttf"); /* IE9 Compat Modes */
    src: local("Inter"),
      url("./Inter/static/Inter-SemiBold.ttf") format("truetype"); /* Safari, Android, iOS */
  }
  @font-face {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    src: url("./Inter/static/Inter-Bold.ttf"); /* IE9 Compat Modes */
    src: local("Inter"), url("./Inter/static/Inter-Bold.ttf") format("truetype"); /* Safari, Android, iOS */
  }
  @font-face {
    font-family: "Inter";
    font-style: normal;
    font-weight: 800;
    src: url("./Inter/static/Inter-ExtraBold.ttf"); /* IE9 Compat Modes */
    src: local("Inter"),
      url("./Inter/static/Inter-ExtraBold.ttf") format("truetype"); /* Safari, Android, iOS */
  }
  @font-face {
    font-family: "Inter";
    font-style: normal;
    font-weight: 900;
    src: url("./Inter/static/Inter-Black.ttf"); /* IE9 Compat Modes */
    src: local("Inter"), url("./Inter/static/Inter-Black.ttf") format("truetype"); /* Safari, Android, iOS */
  }
  
 